.card {
    position: relative;
    width: 240px;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10%;
    background: var(--second);
}

.card .percent {
    position: relative;
    width: 225px;
    height: 225px;
}

.card .percent svg {
    position: relative;
    width: 225px;
    height: 225px;
    transform: rotate(-90deg);
}

.card .percent svg circle {
    width: 100%;
    height: 100%;
    fill: transparent;
    stroke-width: 4;
    stroke: var(--base);
    transform: translate(13px, 13px);
}

.card .percent svg circle:nth-child(2) {
    stroke: var(--third);
    stroke-dasharray: 630;
    /*
    should be calculate like that : calc(630 * var(--num) / 100)
    but it doesn't work on my phone
    */
    stroke-dashoffset: var(--offset);
}

.card .percent svg circle:nth-child(2).reveal {
    stroke-dashoffset: 630;
}

.card .percent svg circle:nth-child(2).reveal.active {
    /*
   should be calculate like that : calc(630 * var(--num) / 100)
   but it doesn't work on my phone
   */
    stroke-dashoffset: var(--offset);
    transition: stroke-dashoffset ease-in-out 2s;
}


.dot {
    position: absolute;
    z-index: 10;
    inset: 10px;
}

.dot.reveal {
    opacity: 0;
    transform : rotate(0deg);
}

.dot.reveal.active {
    animation: dotAppears 2s ease-in-out forwards;
}

@keyframes dotAppears {
    0% {
        opacity: 0;
        transform : rotate(0deg);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform : rotate(calc(3.6deg * var(--num)));
    }
}

.img.competence {
    position: absolute;
    width: 140px;
    aspect-ratio: 1;
    top : 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dot::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    top: -2px;
    left: 50%;
    transform : translateX(-50%);
    border-radius: 50%;
    background: var(--third);
    box-shadow: 0 0 10px var(--third);
}