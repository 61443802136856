.nav-open {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--base);
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(0);
    z-index: 1;
    transition: transform 1s ease-out;
}

.ul-open {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 50%;
    justify-content: space-around;
}

.nav-a {
    @apply text-2xl;
    @apply font-bold;
    @apply md:font-normal;
    @apply md:text-lg;
}

.open-anim {
    animation: slide 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}


@keyframes slide {
    0% {
        transform: translateY(-1000%);
    }
    100% {
        transform: translate(0, 0);
    }
}

.li-close {
}