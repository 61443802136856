.carousel {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    list-style: none;
}
.carousel-control-prev {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 10;
    transform: translateY(-50%);
}

.carousel-control-next {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 10;
    transform: translateY(-50%);
}

.carousel-img.inactive {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.7s;
}

.carousel-img.active {
    opacity: 1;
    transition: opacity 0.7s;
}
